@import "styles/utils/mixins";

.items {
  display: block;
}

.group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.item {
  min-height: 450px;
  border: 1px solid #dfe0e7;
  grid-column: span 3;
  position: relative;
  flex: 1;
}

.size-1-3 {
  width: 33.33%;
  flex: 1 1 33.33%;
}

.size-1-2 {
  width: 50%;
  flex: 1 1 50%;
}

.size-2-3 {
  width: 66.67%;
  flex: none;
}

@include small-screen {
  .items {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    width: 100%;
    flex: none;
    min-height: 180px;
  }
}
