@import "styles/utils/mixins";

.block {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  padding: 60px;
  background-color: #fff;

  &:hover {
    background-color: #efeff3;
  }
}

.card {
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.category {
  display: inline-block;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  padding: 8px 17px;
  margin-bottom: 60px;
  border: 1px solid #bab9c0;
  border-radius: 40px;
}

.title {
  margin-bottom: 28px;
}

.date {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  height: 100%;
  align-items: flex-end;
  margin-top: 15px;
}

@include small-screen {
  .block {
    padding: 30px 22px;
  }

  .title {
    margin-bottom: 12px;
  }

  .category {
    margin-bottom: 20px;
  }

  .date {
    margin-top: 25px;
  }
}
