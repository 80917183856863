@import "styles/utils/mixins";

.block {
  width: 100%;
  height: 100%;
  display: block;
  padding: 60px 65px;
  background-color: #012f6b;
  position: relative;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: inherit;
}

.icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  svg {
    width: 18px;
    height: 18px;
    fill: #fff;
  }
}

.title {
  text-align: left;
  display: flex;
  margin-bottom: 34px;
}

.description {
  font-weight: 600;
  margin-bottom: 25px;
}

.details {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.date,
.price {
  display: flex;
  align-items: center;
  align-items: flex-start;
  font-size: 15px;
  line-height: 24px;
  font-weight: 700;
}

@include small-screen {
  .block {
    padding: 30px 30px 27px;
  }

  .title {
    margin-bottom: 15px;
  }

  .description {
    margin-bottom: 25px;
  }

  .date {
    margin-bottom: 7px;
  }
}
