@import "styles/utils/mixins";

.block {
  width: 100%;
  height: 100%;
  display: block;
  padding: 60px 65px;
  background-color: #012f6b;
  position: relative;
  z-index: 2;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(24 22 42 / 20%) 40%,
      rgba(24 22 42 / 80%) 86.5%
    );
  }
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: inherit;
  z-index: 2;
}

.category {
  display: inline-block;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  padding: 8px 17px;
  margin-bottom: 60px;
  border: 1px solid #fff;
  border-radius: 40px;
}

.icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  svg {
    width: 18px;
    height: 18px;
    fill: #fff;
  }
}

.title {
  text-align: left;
  display: flex;
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 34px;
}

.details {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.date,
.price {
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 24px;
  font-weight: 700;
}

@include small-screen {
  .block {
    padding: 26px 30px 29px;
  }

  .title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
  }
}
