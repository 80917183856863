@import "styles/utils/mixins";

.wrapper {
  padding: 85px 0;

  .more {
    width: 100%;
  }
}

.loader {
  width: 100%;
  padding-top: 40px;
  position: relative;
  z-index: 2;

  &::after {
    content: "";
    position: absolute;
    top: 83%;
    transform: translate(0, -50%);
    left: 0;
    right: 0;
    height: 1px;
    background: #18162d;
    z-index: -1;
  }
}

.loader-wrapper {
  display: inline-flex;
  align-items: center;
  position: relative;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  padding: 0 30px;
  background-color: #fff;

  &:hover,
  &:hover rect {
    color: #ce1050;
    fill: #ce1050;
  }

  & span {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: 14px;
  }
}

@include small-screen {
  .wrapper {
    padding: 0 0 65px;
  }
}
