@import "styles/utils/mixins";

.block {
  width: 100%;
  height: 100%;
  display: inline-block;
  padding: 25px 0;
  overflow: hidden;
  position: relative;
  background-color: #fff;

  &::after,
  &::before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 400px;
    border: 1px solid #dfe0e7;
    box-sizing: border-box;
    border-radius: 50%;
    z-index: 0;
  }

  &::after {
    top: -225px;
  }

  &::before {
    bottom: -225px;
  }
}

.card {
  max-width: 400px;
  margin: auto;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ce1050;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  aspect-ratio: 1/1;
}

.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #012f6b;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25px;
  right: 25px;
}

.icon {
  width: 24px;
  height: 24px;

  path:first-of-type {
    stroke: #fff;
  }

  path:last-of-type {
    fill: #fff;
  }
}

.calendar {
  margin-right: 12px;

  svg {
    width: 18px;
    height: 18px;
    fill: #fff;
  }
}

.title {
  text-align: center;
  display: flex;
}

.date {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 27px;
}

@include small-screen {
  .card {
    min-height: 300px;
    max-width: 300px;
  }

  .date {
    margin-bottom: 20px;
  }
}
